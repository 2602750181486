// THIS FILE IS AUTOGENERATED. DO NOT EDIT MANUALLY.

import add_evens from './add-evens/problem.json';
import adjacent_vowels from './adjacent-vowels/problem.json';
import alpha_order from './alpha-order-string/problem.json';
import answer_cell from './answer-call/problem.json';
import any_two_equal from './any-two-equal/problem.json';
import bball_stats from './basketball-stats/problem.json';
import biggerTwo from './bigger-two/problem.json';
import boolean_xnor from './boolean-xnor/problem.json';
import boolean_xor from './boolean-xor/problem.json';
import calculate_absolute from './calculate-absolute/problem.json';
import check_id from './check-same-identification/problem.json';
import close_or_equal from './close-or-equal/problem.json';
import consecutive_count from './consecutive-count/problem.json';
import count_pairs from './count-pairs/problem.json';
import count_peaks from './count-peaks/problem.json';
import countRepeat from './count-repeat/problem.json';
import count_vowels from './count-vowels/problem.json';
import dating_filter from './dating-filter/problem.json';
import decimal_match from './decimal-match/problem.json';
import double23 from './double-23/problem.json';
import double_nth_char from './double-every-nth-char/problem.json';
import double_letter from './double-letter/problem.json';
import double_nth from './double_n-th/problem.json';
import double_odd from './double-odd/problem.json';
import eat_outside from './eat-outside/problem.json';
import every_other_repeated from './every-other-repeated/problem.json';
import exponential_list_check from './exponential_list_check/problem.json';
import extract_decimal from './extract-decimal/problem.json';
import fibo_lessthan from './fibolesserthan/problem.json';
import first_a_then_b from './first-a-then-b/problem.json';
import fix23 from './fix-23/problem.json';
import is_between from './float-in-between/problem.json';
import is_same from './fournier-problem/problem.json';
import four_two_relationship from './four-two-relationship/problem.json';
import final_grade from './grade-calculator/problem.json';
import grade_contribution from './grade-contribution/problem.json';
import greater_difference from './greater-difference/problem.json';
import greater_than_double from './greater-than-double/problem.json';
import have_3_threes from './have_three/problem.json';
import in_order from './in-order/problem.json';
import int_bool_equality from './int-bool-equality/problem.json';
import integer_conversion_loss from './integer-conversion-loss/problem.json';
import intersecting_metro from './intersecting-metro/problem.json';
import is_18 from './is-18/problem.json';
import is_even from './is-even/problem.json';
import is_multiple from './is-multiple/problem.json';
import list_match from './list_equalizer/problem.json';
import list_equate from './list-equate/problem.json';
import longest_common_substring from './longest-common-substring/problem.json';
import lucky_13 from './lucky-13/problem.json';
import make_combinations from './make-combinations/problem.json';
import make_ends from './make-ends/problem.json';
import make_fibonacci from './make-fibonacci/problem.json';
import series from './make-series/problem.json';
import makes_ten from './makes-ten/problem.json';
import maxOfThree from './max-of-three/problem.json';
import median_num from './median-num/problem.json';
import middle_characters from './middle-characters/problem.json';
import middle_two from './middle-two/problem.json';
import modulo_3 from './modulo-3/problem.json';
import neighbour_repeat from './Neighbouring_Repeats/problem.json';
import nbr_increasing_sequences from './number-increasing-sequences/problem.json';
import ordered_sequence from './ordered_sequence/problem.json';
import plusTwo from './plus-two/problem.json';
import preceding_number25 from './preceding-number-25/problem.json';
import pull_primes from './pull-primes/problem.json';
import pyramid_volume from './pyramid-problem/problem.json';
import range_chars from './range-chars/problem.json';
import remove_vowels from './remove-vowels/problem.json';
import remove_vowels_once from './remove-vowels-once/problem.json';
import repeated_numbers from './repeat-numbers/problem.json';
import middleTwo from './return-middle-two/problem.json';
import reverse_string from './reverse-string/problem.json';
import assign_roll_numbers from './roll-numbers/problem.json';
import same_adjacent from './same-adjacent/problem.json';
import start1 from './start-one/problem.json';
import strings_args from './strings-arg/problem.json';
import sum_7 from './sum_7/problem.json';
import sum_even_special from './sum-even-special/problem.json';
import sum_first_half from './sum-first-half/problem.json';
import sum_first_two from './sum-first-two/problem.json';
import sum_oddneven_list from './sum-oddneven-index/problem.json';
import unique_sum from './sum-of-elements/problem.json';
import sum_of_odd from './sum-of-odd-numbers/problem.json';
import swapEnds from './swap-ends/problem.json';
import temperature_convert from './temperature-convert/problem.json';
import Third_Largest from './third-largest/problem.json';
import triangle_area from './triangle-area/problem.json';
import two_as_one from './two-as-one/problem.json';
import upper_even_i from './upper-even/problem.json';
import vowel_caps from './upper-vowels/problem.json';
import vowel_finder from './Vowel_FInder/problem.json';
import zig_zag from './zig-zag/problem.json';
const problems = [
    add_evens,
    adjacent_vowels,
    alpha_order,
    answer_cell,
    any_two_equal,
    bball_stats,
    biggerTwo,
    boolean_xnor,
    boolean_xor,
    calculate_absolute,
    check_id,
    close_or_equal,
    consecutive_count,
    count_pairs,
    count_peaks,
    countRepeat,
    count_vowels,
    dating_filter,
    decimal_match,
    double23,
    double_nth_char,
    double_letter,
    double_nth,
    double_odd,
    eat_outside,
    every_other_repeated,
    exponential_list_check,
    extract_decimal,
    fibo_lessthan,
    first_a_then_b,
    fix23,
    is_between,
    is_same,
    four_two_relationship,
    final_grade,
    grade_contribution,
    greater_difference,
    greater_than_double,
    have_3_threes,
    in_order,
    int_bool_equality,
    integer_conversion_loss,
    intersecting_metro,
    is_18,
    is_even,
    is_multiple,
    list_match,
    list_equate,
    longest_common_substring,
    lucky_13,
    make_combinations,
    make_ends,
    make_fibonacci,
    series,
    makes_ten,
    maxOfThree,
    median_num,
    middle_characters,
    middle_two,
    modulo_3,
    neighbour_repeat,
    nbr_increasing_sequences,
    ordered_sequence,
    plusTwo,
    preceding_number25,
    pull_primes,
    pyramid_volume,
    range_chars,
    remove_vowels,
    remove_vowels_once,
    repeated_numbers,
    middleTwo,
    reverse_string,
    assign_roll_numbers,
    same_adjacent,
    start1,
    strings_args,
    sum_7,
    sum_even_special,
    sum_first_half,
    sum_first_two,
    sum_oddneven_list,
    unique_sum,
    sum_of_odd,
    swapEnds,
    temperature_convert,
    Third_Largest,
    triangle_area,
    two_as_one,
    upper_even_i,
    vowel_caps,
    vowel_finder,
    zig_zag,
];
export default problems;
